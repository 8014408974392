<template>
    <div>
        <div class="text-left">
            <h2 class="text-uppercase"><span class="text-primary">Logopädie</span></h2>
            <p class="text-muted mb-0">{{ $t('home.logo_text') }}</p>
        </div>
        <div class="row mt-4">
            <div class="col-lg-4">
                <div class="p-2 shadow"><img class="img-fluid" src="@/assets/img/leistungen/logo_detail.jpg" alt=""></div>
            </div>
            <div class="col-lg-8 mt-5">
                <div class="row">
                    <div class="col-xl-12">
                        <div class="p-2 bg-primary">
                            <div class="border border-white border-2 p-lg-5 p-4 d-flex align-items-center">
                                <div class="ps-3">
                                    <h4 class="text-white mb-1">Behandlungsfelder / Störungen</h4>
                                    <div class="row gy-4 pt-4 text-white">
                                        <div class="col-xl-6">
                                            <ul class="list-check list-unstyled row px-3 gy-2">
                                                <li><i class="fal fa-circle fa-2xs"></i> Sprachstörungen</li>
                                                <li><i class="fal fa-circle fa-2xs"></i> Sprechprobleme</li>
                                                <li><i class="fal fa-circle fa-2xs"></i> Stimmstörungen</li>
                                                <li><i class="fal fa-circle fa-2xs"></i> Schluckstörungen</li>
                                            </ul>
                                        </div>
                                        <div class="col-xl-6">
                                            <ul class="list-check list-unstyled row px-3 gy-2">
                                                <li><i class="fal fa-circle fa-2xs"></i> Redeflussprobleme</li>
                                                <li><i class="fal fa-circle fa-2xs"></i> Hörstörungen</li>
                                                <li><i class="fal fa-circle fa-2xs"></i> Mutismus</li>
                                                <li><i class="fal fa-circle fa-2xs"></i> Trachealkanülmanagement
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: '',
    data() {
        return {
        };
    },
    methods: {
    },
    computed: {
    },
    mounted() {
    }
};
</script>
