<template>
    <div>
        <div class="container">
            <div class="text-center" id="hausbesuche">
                <h2 class="text-uppercase lined lined-center"><span class="text-primary">Hausbesuche</span></h2>
                <p class="text-muted mb-5">Wir sind für Sie unterwegs</p>
            </div>
            <div class="row align-items-stretch gx-0">
                <div class="col-lg-6">
                    <div class="p-2 shadow"><img class="img-fluid" src="@/assets/img/hausbesuch.jpg"></div>
                </div>
                <div class="col-lg-6">
                    <div class="p-5 h-100">
                        <p class="text-muted">Wenn Sie es bevorzugen, in Ihren eigenen vier Wänden behandelt zu werden:
                            Kein Problem, wir behandeln Sie gerne Zuhause!</p>
                        <ul class="list-unstyled mb-0 text-muted">
                            <li class="mb-2"><a class="reset-anchor"> <i class="text-primary me-3 fas fa-phone"></i><a
                                        v-bind:href="`tel:${$t('frame.phone')}`">{{ $t('frame.phone') }}</a></a></li>
                            <li class="mb-2"><a class="reset-anchor"> <i class="text-primary me-3 far fa-envelope"></i><a
                                        v-bind:href="`mailto:${$t('frame.email')}`">{{ $t('frame.email') }}</a></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: '',
    data() {
        return {
        };
    },
    methods: {
    },
    computed: {
    },
    mounted() {
    }
};
</script>
