<template>
    <div>
        <div class="container">
            <div class="text-center">
                <h2 class="text-uppercase lined lined-center"><span class="text-primary">Kompetenzzentrum </span>Neurologie
                </h2>
                <p class="text-muted mb-5">Weil wir das große Ganze sehen</p>
            </div>
            <div class="row align-items-stretch gx-0">
                <div class="col-lg-6">
                    <b-carousel id="carousel-2" class="p-2 shadow" v-model="slide" :interval="5000" controls indicators fade
                        @sliding-start="onSlideStart" @sliding-end="onSlideEnd">
                        <b-carousel-slide class="align-items-center b-carousel-slide">
                            <template slot="img">
                                <img class="slider-background" src="@/assets/img/leistungen/neuro/5.jpg">
                            </template>
                        </b-carousel-slide>
                        <b-carousel-slide class="align-items-center b-carousel-slide">
                            <template slot="img">
                                <img class="slider-background" src="@/assets/img/leistungen/neuro/7.jpg">
                            </template>
                        </b-carousel-slide>
                        <b-carousel-slide class="align-items-center b-carousel-slide">
                            <template slot="img">
                                <img class="slider-background" src="@/assets/img/leistungen/neuro/6.jpg">
                            </template>
                        </b-carousel-slide>
                        <b-carousel-slide class="align-items-center b-carousel-slide">
                            <template slot="img">
                                <img class="slider-background" src="@/assets/img/leistungen/neuro/4.jpg">
                            </template>
                        </b-carousel-slide>
                        <b-carousel-slide class="align-items-center b-carousel-slide">
                            <template slot="img">
                                <img class="slider-background" src="@/assets/img/leistungen/neuro/3.jpg">
                            </template>
                        </b-carousel-slide>
                        <b-carousel-slide class="align-items-center b-carousel-slide">
                            <template slot="img">
                                <img class="slider-background" src="@/assets/img/leistungen/neuro/2.jpg">
                            </template>
                        </b-carousel-slide>
                        <b-carousel-slide class="align-items-center b-carousel-slide">
                            <template slot="img">
                                <img class="slider-background" src="@/assets/img/leistungen/neuro/1.jpg">
                            </template>
                        </b-carousel-slide>

                    </b-carousel>
                </div>
                <div class="col-lg-6">
                    <div class="p-5 h-100">
                        <p class="text-dark">
                            Unsere Fachbereiche arbeiten eng interdisziplinär zusammen, um die individuellen Bedürfnisse und
                            Herausforderungen unserer Patienten zu berücksichtigen. Wir behandeln eine Vielzahl von
                            neurologischen Erkrankungen, darunter:
                        </p>
                        <ul class="list-unstyled text-dark">
                            <li class="mb-2"><i class="text-mdark fal fa-circle fa-2xs"></i> Spinale Muskelatrophie (SMA)
                            </li>
                            <li class="mb-2"><i class="text-mdark fal fa-circle fa-2xs"></i> Muskeldystrophie (MD)</li>
                            <li class="mb-2"><i class="text-mdark fal fa-circle fa-2xs"></i> Multiple Sklerose (MS)</li>
                            <li class="mb-2"><i class="text-mdark fal fa-circle fa-2xs"></i> Amyotrophe Lateralsklerose
                                (ALS)</li>
                            <li class="mb-2"><i class="text-mdark fal fa-circle fa-2xs"></i> Migräne</li>
                            <li class="mb-2"><i class="text-mdark fal fa-circle fa-2xs"></i> Demenz</li>
                            <li class="mb-2"><i class="text-mdark fal fa-circle fa-2xs"></i> Parkinson</li>
                            <li class="mb-2"><i class="text-mdark fal fa-circle fa-2xs"></i> Schlaganfall</li>
                        </ul>
                        <p class="text-dark">
                            Unsere erfahrenen Therapeuten bringen ihr Fachwissen und ihre Expertise ein, um die Therapie auf
                            die spezifischen Bedürfnisse jedes Patienten abzustimmen. Durch gezielte Übungen, gezielte
                            Behandlungen und individuelle Therapiepläne helfen wir unseren Patienten, ihre Mobilität zu
                            verbessern, ihre Fähigkeiten zu stärken und ihre motorischen Fähigkeiten zu
                            entwickeln.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: '',
    data() {
        return {
        };
    },
    methods: {
    },
    computed: {
    },
    mounted() {
    }
};
</script>

<style scoped>
.slider-background {
    width: 100%;
    object-fit: cover;
}
</style>
