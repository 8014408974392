<template>
    <div>
        <div class="text-right">
            <h2 class="text-uppercase"><span class="text-primary">Physiotherapie</span></h2>
            <p class="text-muted mb-0">{{ $t('home.physio_text') }}</p>
        </div>
        <div class="row">
            <div class="col-lg-8">
                <div class="row">
                    <div class="col-xl-12 mt-5">
                        <div class="p-2 bg-primary">
                            <div class="border border-white border-2 p-lg-5 p-4 d-flex align-items-center">
                                <div class="ps-3">
                                    <h4 class="text-white mb-1">Behandlungsfelder / Störungen</h4>
                                    <div class="row gy-4 pt-4 text-white">
                                        <div class="col-xl-6">
                                            <ul class="list-check list-unstyled row px-3 gy-2">
                                                <li><i class="fal fa-circle fa-2xs"></i> Fehlhaltungen</li>
                                                <li><i class="fal fa-circle fa-2xs"></i> Kinderkrankengymnastik</li>
                                                <li><i class="fal fa-circle fa-2xs"></i> Neurologische Erkrankungen</li>
                                                <li><i class="fal fa-circle fa-2xs"></i> Lymphabflussstörungen</li>
                                            </ul>
                                        </div>
                                        <div class="col-xl-6">
                                            <ul class="list-check list-unstyled row px-3 gy-2">
                                                <li><i class="fal fa-circle fa-2xs"></i> Sportverletzungen aller Art</li>
                                                <li><i class="fal fa-circle fa-2xs"></i> Schmerzen des Bewegungsapparates
                                                </li>
                                                <li><i class="fal fa-circle fa-2xs"></i> Verletzungen an Gelenken</li>
                                                <li><i class="fal fa-circle fa-2xs"></i> Wirbelsäulenverkrümmungen</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="p-2 shadow mt-2"><img class="img-fluid" src="@/assets/img/leistungen/physio_detail.jpg" alt=""></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: '',
    data() {
        return {
        };
    },
    methods: {
    },
    computed: {
    },
    mounted() {
    }
};
</script>
