<template>
    <layout-default>
        <section class="hero hero-bright bg-cover bg-top"
            v-bind:style="{ 'background-image': 'url(' + require('../assets/img/leistung_banner.jpg') + ')' }">
            <div class="container z-index-20 mt-lg-5">
                <div class="row align-items-lg-center gy-4">
                    <div class="col-lg-7 order-2 order-lg-1">
                        <h1 class="text-uppercase text-white">Unsere <span class="text-success">Leistungen</span></h1>
                        <p class="lead text-white mb-0">Alles aus einer Hand</p>
                    </div>
                </div>
            </div>
        </section>
        <section class="pb-5">
            <div class="container">
                <div class="row gy-5" href="#logo" id="logo">
                    <leistung-logo />
                </div>
            </div>
        </section>
        <section class="bg-light pb-5">
            <div class="container">
                <div class="row gy-5" href="#physio" id="physio">
                    <leistung-physio />
                </div>
            </div>
        </section>
        <section class="pb-5">
            <div class="container">
                <div class="row gy-5" href="#ergo" id="ergo">
                    <leistung-ergo />
                </div>
            </div>
        </section>
        <section class="bg-light">
            <div class="container">
                <div class="row gy-5" href="#neuro" id="neuro">
                    <leistung-neuro />
                </div>
            </div>
        </section>
        <section>
            <leistung-hausbesuch />
        </section>
    </layout-default>
</template>

<script>
import LayoutDefault from '../layouts/LayoutDefault.vue';
import LeistungPhysio from '../components/Leistungen/LeistungPhysio.vue';
import LeistungErgo from '../components/Leistungen/LeistungErgo.vue';
import LeistungLogo from '../components/Leistungen/LeistungLogo.vue';
import LeistungNeuro from '../components/Leistungen/LeistungNeurozentrum.vue';
import LeistungHausbesuch from '../components/Leistungen/LeistungHausbesuch.vue';

export default {
    name: 'Leistungen',
    components: {
        LayoutDefault,
        LeistungPhysio,
        LeistungErgo,
        LeistungLogo,
        LeistungNeuro,
        LeistungHausbesuch
    },
    data: () => ({
    }),
    mounted() {
    },
    methods: {
    },
    watch: {
    }
};
</script>
